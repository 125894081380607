const INFO = {
	main: {
		title: "Akram Louze | Portfolio",
		name: "Akram L.",
		email: "contact@akraml.com",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/itsakraml",
		github: "https://github.com/AkramLZ",
		linkedin: "https://linkedin.com/in/akraml",
		instagram: "https://instagram.com/itsakraml",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Software Developer",
		description:
			"Hi, I'm Akram Louze, a fervent learner and developer, currently pursuing a degree in Computer Science at Oum El-Bouaghi University. At 18, my days are filled with academic endeavors and practical experiences at MakeraGames. I'm driven by the excitement of coding something new every day. I thrive on collaboration and am always on the lookout for new learning opportunities.",
	},

	about: {
		title: "I'm Akram Louze,",
		description:
			"I am a passionate software developer and currently studying Computer science in Oum El-Bouaghi University, I also worked with MakeraGames as a part-time software engineer. I contributed and made many projects and some of them are free and open-source, If you're interested feel free to check them on my GitHub page!",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "PluginLoader",
			description:
				"A proof-of-concept project that introduces a feature where you can load plugins (or java softwares in general) into your server without needing to redownload or save it in disk, all done in memory only!",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/java/java.png",
			linkText: "View on GitHub",
			link: "https://github.com/AkramLZ/PluginLoader",
		},

		{
			title: "ServerSync",
			description:
				"A system that aims to automate servers registration on BungeeCord/Velocity networks without needing to manually configure the proxies each time. Ideal for servers that auto-creates servers with unique names each time.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/java/java.png",
			linkText: "View on GitHub",
			link: "https://github.com/AkramLZ/ServerSync",
		}
	],
};

export default INFO;
