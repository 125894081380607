const SEO = [
	{
		page: "home",
		description:
			"Hi, I'm Akram Louze, a fervent learner and developer, currently pursuing a degree in Computer Science at Oum El-Bouaghi University. At 18, my days are filled with academic endeavors and practical experiences at MakeraGames. I'm driven by the excitement of coding something new every day. I thrive on collaboration and am always on the lookout for new learning opportunities.",
		keywords: ["AkramL", "Akram Louze", "Akram L"],
	},

	{
		page: "about",
		description:
			"Hi, I'm Akram Louze, a fervent learner and developer, currently pursuing a degree in Computer Science at Oum El-Bouaghi University. At 18, my days are filled with academic endeavors and practical experiences at MakeraGames. I'm driven by the excitement of coding something new every day. I thrive on collaboration and am always on the lookout for new learning opportunities.",
		keywords: ["AkramL", "Akram Louze", "Akram L"],
	},

	{
		page: "articles",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
		keywords: ["AkramL", "Akram Louze", "Akram L"],
	},

	{
		page: "projects",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to.",
		keywords: ["AkramL", "Akram Louze", "Akram L"],
	},

	{
		page: "contact",
		description:
			"If you're interested in collaborating on a project, feel free to reach out to me. I'm always open to new ideas and opportunities.",
		keywords: ["AkramL", "Akram Louze", "Akram L"],
	},
	{
		page: "progres-data-checker",
		description:
			"Check if your data in PROGRES platform is vulnerable to be breached.",
		keywords: ["PROGRES", "mesrs.dz", "webetu", "webfve"],
	}
];

export default SEO;
